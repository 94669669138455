import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import cn from 'classnames';
import './SidebarItem.scss';

type Props = {
	sidebarIsOpen: boolean;
	children: React.ReactNode;
	to: string;
	icon?: React.ReactNode;
};

export default function SidebarItem({
	children,
	to,
	icon,
	sidebarIsOpen,
}: Props) {
	const location = useLocation();
	const _to = to.startsWith('/') ? to : `/${to}`;

	const isActive =
		location?.pathname?.replaceAll('/', '') === _to?.replaceAll('/', '');

	return (
		<AnimatePresence>
			<Link
				className={cn('sidebar-item', {
					active: isActive,
					'sidebar-closed': !sidebarIsOpen,
				})}
				to={_to}
			>
				<div className="icon">{icon}</div>
				{sidebarIsOpen && (
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
					>
						<span className="label">{children}</span>
					</motion.div>
				)}
			</Link>
		</AnimatePresence>
	);
}
