import { AppState, EnrollmentInfoObject, initialState } from './initialState';
import {
	COMMON_CLEAN_ERROR,
	USER_ENROLLMENT,
	USER_LOGIN,
	USER_LOGOUT,
	USER_PROFILE,
} from '../actionsTypes';

type ActionObject<T> = {
	type: string;
	payload: T;
};

const updateState = (state: AppState, props: any) =>
	Object.assign({}, state, {
		...props,
	});

function rootReducer(state = initialState, action: ActionObject<any>) {
	switch (action.type) {
		case USER_LOGIN:
			return updateState(state, {
				...action.payload,
			});
		case USER_LOGOUT:
			return {
				// ...state, ...{
				...initialState,
				...{
					token: null,
					userInfo: null,
					isLogged: false,
					error: action.payload?.error,
				},
			};
		case USER_PROFILE:
			return updateState(state, {
				userInfo: action.payload.userInfo,
			});
		case COMMON_CLEAN_ERROR:
			return updateState(state, {
				error: null,
			});
		case USER_ENROLLMENT: {
			const payload = action.payload as EnrollmentInfoObject;

			return updateState(state, {
				lastEnrollmentSteps: {
					...state.lastEnrollmentSteps,
					...payload,
				},
			});
		}
	}
	return state;
}

export default rootReducer;
