export const UserLogin = {
	'Sign in': 'Inicio de Sesión',
	'Please type your username and password':
		'Por favor ingresa tu nombre de usuario y contraseña',
	Email: 'Correo electrónico',
	Password: 'Contraseña',
	'Forgot your password?': '¿Olvidaste tu contraseña?',
	Enter: 'Ingresar',
	'Stil not registered?': '¿Aún no estás registrado?',
	'Sign up here!': '¡Inscríbete aquí!',
};
