import { create } from 'zustand';
import { CourseType } from '../Views/Course/hooks/useCourses';
import { CourseUnitResponse } from '../Hooks/useCourse';
import { ResourceResponse } from '../Hooks/useResource';

type useCoursesStoreType = {
	courses: CourseType[];
	saveCoursesData: (arg: CourseType[]) => void;
};

export const useCoursesStore = create<useCoursesStoreType>((set) => ({
	courses: [],
	saveCoursesData: (courseData) =>
		set((state) => ({ courses: [...state.courses, ...courseData] })),
}));

type useCourseStoreType = {
	course: CourseUnitResponse | undefined;
	saveCourseData: (arg: CourseUnitResponse | undefined) => void;
};

export const useCourseStore = create<useCourseStoreType>((set) => ({
	course: undefined,
	saveCourseData: (course) => set(() => ({ course })),
}));

type useResourceType = {
	resource: ResourceResponse | undefined;
	saveResourceData: (arg: ResourceResponse) => void;
};

export const useResourceStore = create<useResourceType>((set) => ({
	resource: undefined,
	saveResourceData: (resource) => set(() => ({ resource })),
}));
