import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Container } from 'reactstrap';
import { HeaderNavBar, SidebarMenu } from '../../../Components';
import { InternalBreadcrumb } from '../../InternalBreadcrumb';
import { useUserStore } from '../../../stores/user';
import './styles.scss';

export default function MainLayout() {
	const [isOpen, setIsOpen] = React.useState(true);
	const token = useUserStore((state) => state.token);

	if (!token) {
		return <Navigate to="/" replace />;
	}

	return (
		<div className={'MainLayout'}>
			<HeaderNavBar />
			<Container fluid className="MainLayout_Wrapper">
				<SidebarMenu context={{ isOpen, setIsOpen }} />
				<div
					className={`w-main ${isOpen ? 'open' : 'close'}`}
					style={{ overflowY: 'auto' }}
				>
					<InternalBreadcrumb key={'InternalBreadcrumb'} />
					<Outlet />
				</div>
			</Container>
		</div>
	);
}
