export type UserInstance = {
	id?: string;
	_id?: number;
	firstname: string;
	lastname: string;
	email: string;
	image?: string | null;
	birthday?: Date | null;
	name: string;
	dni: string;
};

export type CourseCacheObject = {
	id: number;
	memberId: number;
	name: string;
};

export type UnitCacheObject = {
	id: number;
	memberId: number;
	courseId: number;
	name: string;
};

export type LessonCacheObject = {
	id: number;
	memberId: number;
	unitId: number;
	courseId: number;
	name: string;
};

export type ResourceCacheObject = {
	id: number;
	name: string;
	resource_type_id: number;
};

export type OrientacionTypeObject = {
	cod: number;
	orientacion: string;
	image?: string;
};

export type EnrollmentInfoObject = {
	id: string;
	/** Documento Nacional de Identificación del usuario a enrrolarse. */
	dni: string;
	/** Correo electrónico del usuario a enrrolarse. */
	email: string;
	/** Identificador único con BioMatch. */
	token?: string;
	/** Identificador único del Enrollment en el LMS. */
	tokenEnrollment?: string;
	/** Listado de orientaciones solicitadas en la última request. */
	orientaciones?: OrientacionTypeObject[];
	/** Endpoint que envió las orientaciones */
	endpoint?: 'user' | 'enroll';
};

export type ErrorObject = {
	message: string;
	code?: number;
	type?: 'danger' | 'success';
};

export interface MemberProgressResourceObject<T> {
	Resource: {
		id: T;
	};
	ResourceProgress: {
		completed: boolean;
	};
}

export interface MemberProgressObject<T> {
	memberId?: number;
	Resources: MemberProgressResourceObject<T>[];
	completed: T;
	pending: T;
	progress: T;
	required: T;
	total: T;
}

export type AppState = {
	token?: string;
	isLogged?: boolean;
	userInfo?: UserInstance;
	error?: ErrorObject;
	courses?: CourseCacheObject[];
	units: UnitCacheObject[];
	lessons: LessonCacheObject[];
	resources: ResourceCacheObject[];
	lastEnrollmentSteps?: EnrollmentInfoObject;
	progress: MemberProgressObject<string | number>[];
};

export const initialState: AppState = {
	token: undefined,
	isLogged: false,
	userInfo: undefined,
	error: undefined,
	courses: undefined,
	units: [],
	lessons: [],
	resources: [],
	lastEnrollmentSteps: undefined,
	progress: [],
};
