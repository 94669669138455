export const ComponentBiomatchOrientations = {
	'Self Enrollment Process': 'Registro de nuevos usuarios',
	'Facial recognition allows us to validate and guarantee the transparency and quality of the courses we offer you. Next we will request photographs of your identity card and your face. To start the process, click the button below.':
		'El reconocimiento facial nos permite validar y garantizar la transparencia y calidad de los cursos que te ofrecemos. Para comenzar, ten a mano tu carnet de identidad y ubícate en un lugar con buena iluminación.',
	Start: 'Siguiente',
	Back: 'Anterior',
	Take: 'Tomar',
	Rotate: 'Girar',
	'Try again': 'Intentar nuevamente',
	Repeat: 'Repetir',
	'Final Validation': 'Validación final',
	'Uploading info...': 'Subiendo datos...',
	// Data de Biomatch
	'Foto de Sefie': 'Foto de rostro',
	'Gire su rostro hacia Arriba': 'Foto mirando a arriba',
	'Gire su rostro a su Derecha': 'Foto mirando a la derecha',
	'CI frontal': 'Foto documento frontal',
	'CI back': 'Foto documento posterior',
	// Errors
	'There was an error in the validation': 'Hubo un error en la validación',
	'Please consider the following and repeat the front photo, back photo and face photo:':
		'Por favor considera lo siguiente y repite la foto frontal, la foto posterior y la foto del rostro:',
	'Take care of excess or lack of light': 'Cuidar el exceso o falta de luz',
	'Avoid blurry photos': 'Evitar fotografías borrosas',
	Retry: 'Reintentar',
	'Show tutorial': 'Ver tutorial',
	'Taking photo': 'Tomar foto',
	Next: 'Siguiente',
	Validate: 'Validar',
};
