const _appApiUrl = import.meta.env.VITE_API_URL;
const _appStorageKey = import.meta.env.VITE_STORAGEKEY;
const _appXHost = import.meta.env.VITE_X_HOST;
const _nodeEnv = import.meta.env.MODE;
const _isDevelopment = import.meta.env.DEV;
const _isProduction = import.meta.env.PROD;
const _bugSnagKey = import.meta.env.VITE_BUGSNAG_API_KEY;

if (!_appApiUrl) throw Error('REACT_APP_API_URL env variable is not defined');
if (!_appStorageKey)
	throw Error('REACT_APP_STORAGEKEY env variable is not defined');
if (!_appXHost) throw Error('REACT_APP_X_HOST env variable is not defined');
if (!_bugSnagKey)
	throw Error('REACT_APP_BUGSNAG_API_KEY env variable is not defined');

export const appApiUrl = _appApiUrl;
export const appStorageKey = _appStorageKey;
export const appXHost = _appXHost;
export const nodeEnv = _nodeEnv;
export const isDevelopment = _isDevelopment;
export const isProduction = _isProduction;
export const bugSnagKey = _bugSnagKey;
