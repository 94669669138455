import { MyModal } from '../my-modal/MyModal';
import './styles.scss';

type MyAlertProps = {
	message?: string;
	title?: string;
	action?: { click: () => void; label: string };
	isOpen: boolean;
};

export const MyAlert = (props: MyAlertProps) => {
	const action = () => {
		props.action?.click && props.action?.click();
	};

	return (
		<MyModal isOpen={props.isOpen}>
			<MyModal.Body>
				<div className="modal-alert-content">
					{props.title ? (
						<div className="modal-alert-title">{props.title}</div>
					) : null}
					<div className="modal-alert-message">{props.message}</div>
					<div className="modal-alert-actions">
						<button onClick={action} className="alert-button">
							{props.action?.label}
						</button>
					</div>
				</div>
			</MyModal.Body>
		</MyModal>
	);
};
