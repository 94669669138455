import { NavLink } from 'react-router-dom';
import { BreadcrumbComponentType } from 'use-react-router-breadcrumbs';
import { useCourseStore } from '../../stores/courses';

export const ResoucesViewMemberIdBreadcrumb: BreadcrumbComponentType<
	'memberId' | 'unitId' | 'lessonId' | 'resourceId'
> = ({ match }) => {
	const course = useCourseStore((state) => state.course);

	if (!course) {
		return null;
	}

	return (
		<NavLink to={`/units/index/${match.params.memberId}`}>
			{course.name}
		</NavLink>
	);
};
