import { useState } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { LogoColor, LogoGray, LogoWhite } from '../../../Assets';
import { camelize } from '../../../Utils';

import './styles.scss';
import LogoApp from '../../../Components/logo-app/LogoApp';
import { useUserStore } from '../../../stores/user';

export interface ExternalLayoutOutletContext {
	title: string;
	setTitle: (title: string) => void;
}

export default function ExternalLayout() {
	const [title, setTitle] = useState<string>('');
	const outletContext: ExternalLayoutOutletContext = { title, setTitle };
	const isLogged = useUserStore((state) => state.isLogged);
	const navigate = useNavigate();

	if (isLogged) {
		return <Navigate to="/courses" replace />;
	}

	return (
		<div className={`ExternalLayout ${camelize(title)}`}>
			<Container fluid className="ExternalLayout_Wrapper">
				<Row className="h-100 h-auto-sx px-0">
					<Col
						lg={6}
						className="ExternalLayout_Wrapper_Outlet order-2 order-lg-1"
					>
						<header
							className="shadow-sm d-lg-block"
							onClick={() => {
								navigate('/');
							}}
						>
							<LogoApp />
						</header>
						<section>
							<Outlet context={outletContext} />
						</section>
						<footer className="border-top">
							<LogoColor className="d-lg-block" />
							<LogoGray className="d-lg-none" />
						</footer>
					</Col>
					<Col
						lg={6}
						className="ExternalLayout_Wrapper_Image order-1 order-lg-2"
					>
						{title !== '' && (
							<div className="h-100 d-flex justify-content-center align-items-center position-relative pt-5 pb-5">
								<div className="d-lg-none z-index-2 text-center position-relative">
									<LogoWhite className="mb-4" />
									<h1>{title}</h1>
								</div>
								<div className="bg-black h-100 w-100 opacity-50 position-absolute z-index-1 d-lg-none" />
							</div>
						)}
					</Col>
				</Row>
			</Container>
		</div>
	);
}
