import { useContext } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import AppConfigContext from '../../Components/AppConfig/AppConfigContext';
import useBreadcrumbs, { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';

// Local Breadcrumbs processors
import { NormalBreadcrumb } from './NormalBreadcrumb';
import { UnitsBreadcrumb } from './UnitsBreadcrumb';
import { UnitsIndexMemberIdBreadcrumb } from './UnitsIndexMemberIdBreadcrumb';
import { ResoucesViewMemberIdBreadcrumb } from './ResoucesViewMemberIdBreadcrumb';
import { ResoucesViewMemberIdUnitIdBreadcrumb } from './ResoucesViewMemberIdUnitIdBreadcrumb';
import { ResourcesViewMemberIdUnitIdLessonIdResourceIdBreadcrumb } from './ResourcesViewMemberIdUnitIdLessonIdResourceIdBreadcrumb';

export function InternalBreadcrumb() {
	const { manifest } = useContext(AppConfigContext);
	const { t } = useTranslation('Common');

	const routes: BreadcrumbsRoute[] = [
		{
			path: '/courses',
			breadcrumb: NormalBreadcrumb,
			props: { text: t('Courses') },
		},
		{
			path: '/certificates',
			breadcrumb: NormalBreadcrumb,
			props: { text: t('Certificates') },
		},
		{
			path: '/profile',
			breadcrumb: NormalBreadcrumb,
			props: { text: t('My profile') },
		},
		{ path: '/units', breadcrumb: UnitsBreadcrumb },
		{ path: '/units/index', breadcrumb: null },
		{
			path: '/units/index/:memberId',
			breadcrumb: UnitsIndexMemberIdBreadcrumb,
		},
		{ path: '/resources', breadcrumb: UnitsBreadcrumb },
		{ path: '/resources/view', breadcrumb: null },
		{
			path: '/resources/view/:memberId',
			breadcrumb: ResoucesViewMemberIdBreadcrumb,
		},
		{
			path: '/resources/view/:memberId/:unitId',
			breadcrumb: ResoucesViewMemberIdUnitIdBreadcrumb,
		},
		{ path: '/resources/view/:memberId/:unitId/:lessonId', breadcrumb: null },
		{
			path: '/resources/view/:memberId/:unitId/:lessonId/:resourceId',
			breadcrumb: ResourcesViewMemberIdUnitIdLessonIdResourceIdBreadcrumb,
		},
		{
			path: '/faqs',
			breadcrumb: NormalBreadcrumb,
			props: { text: t('Frequent questions') },
		},
	];

	const breadcrumbs = useBreadcrumbs(routes, {
		excludePaths: ['/'],
	});

	return (
		<Breadcrumb>
			<BreadcrumbItem>{manifest.name}</BreadcrumbItem>
			{breadcrumbs.map(({ match, breadcrumb }) => {
				return (
					<BreadcrumbItem key={match.pathname}>{breadcrumb}</BreadcrumbItem>
				);
			})}
		</Breadcrumb>
	);
}
