import { Common } from './Common';
import { UserLogin } from './UserLogin';
import { UserRegister } from './UserRegister';
import { UserForgotPassword } from './UserForgotPassword';
import { UserUpdatePassword } from './UserUpdatePassword';
import { UnitsView } from './UnitsView';
import { Pages } from './Pages';
import { Quiz } from './Quiz';

import { ComponentBiomatchOrientations } from './ComponentBiomatchOrientations';

export const es = {
	Common,
	UserLogin,
	UserRegister,
	UserForgotPassword,
	UserUpdatePassword,
	UnitsView,
	Pages,
	Quiz,
	// Componentes
	ComponentBiomatchOrientations,
};
