import { Spinner } from 'reactstrap';
import './styles.scss';

const Loading = () => {
	return (
		<div className="loading-container">
			<Spinner />
		</div>
	);
};

export default Loading;
