import React from 'react';
import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import getXHost from '../Utils/getXHost';
import { appApiUrl } from '../Utils/env';
import { useUserStore } from '../stores/user';
import { useNavigate } from 'react-router-dom';
export interface ProfileResType {
	error: boolean;
	response: ProfileDataType;
	success: boolean;
}

export interface ProfileDataType {
	User: UserType;
	UserProperty: UserPropertyType;
	VerifiedEmail: VerifiedEmailType;
}

export interface UserType {
	birthday: null;
	email: string;
	firstname: string;
	id: string;
	image: null;
	lastname: string;
	name: string;
}

export interface UserPropertyType {
	dni: DniType;
}

export interface DniType {
	childs: any[];
	id: string;
	params: DniParamsType;
	value: string;
}

export interface DniParamsType {
	type_id: number;
}

export interface VerifiedEmailType {
	created: null;
	created_gmt: null;
	deleted: null;
	deleted_date: null;
	id: null;
	modified: null;
	modified_gmt: null;
	parent_id: null;
	property_key: null;
	property_params: null;
	property_type: null;
	property_value: null;
	user_id: null;
}

const useProfile = () => {
	const navigate = useNavigate();
	const [setUserData, token, userLogout] = useUserStore((state) => [
		state.setUserData,
		state.token,
		state.userLogout,
	]);

	const query = useQuery({
		queryKey: ['profile', token],
		queryFn: async () => {
			try {
				const res = await axios.get<ProfileResType>(
					`${appApiUrl}/profile.json`,
					{
						headers: {
							'X-Token': `Bearer ${token}`,
							'X-Host': getXHost(),
						},
					}
				);
				return res;
			} catch (error) {
				const err = error as AxiosError;
				if (err.response?.status === 401) {
					navigate('/');
					userLogout();
				}
			}
		},
		cacheTime: 30 * 60 * 1000,
	});

	const user = React.useMemo(
		() => ({
			...query.data?.data.response.User,
			dni: query.data?.data.response.UserProperty.dni.value,
		}),
		[
			query.data?.data.response.User,
			query.data?.data.response.UserProperty.dni.value,
		]
	);

	React.useEffect(() => {
		setUserData(user);
	}, [user, setUserData]);

	return { ...query, user };
};

export default useProfile;
