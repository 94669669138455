import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

import { useAppConfigContext } from '../Components/AppConfig/AppConfigContext';
import initFreshChat from '../Utils/freshChat';

import { ExternalLayout, MainLayout } from './Layouts';
import Loading from '../Components/loading/Loading';

const UserUpdatePassword = React.lazy(
	() => import('../Views/User/UpdatePassword')
);
const UserForgotPassword = React.lazy(
	() => import('../Views/User/ForgotPassword')
);
const UserRegister = React.lazy(() => import('../Views/User/Register'));
const UserLoginView = React.lazy(() => import('../Views/User/Login'));
const CourseList = React.lazy(() => import('../Views/Course/List'));
const UnitsView = React.lazy(() => import('../Views/Units/View'));
const ResourceView = React.lazy(() => import('../Views/Resource/View'));
const PagesFaqs = React.lazy(() => import('../Views/Pages/Faqs'));
const CourseCertificates = React.lazy(
	() => import('../Views/Course/Certificates')
);
const ProfileIndex = React.lazy(() => import('../Views/User/Profile'));
const FourZeroFour = React.lazy(
	() => import('../Views/errors/404/FourZeroFour')
);

export default function Router() {
	const { manifest } = useAppConfigContext();

	React.useEffect(() => {
		if (!manifest?.code_chat) return;
		initFreshChat(manifest?.code_chat);
	}, [manifest]);

	return (
		<React.Suspense fallback={<Loading />}>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<UserLoginView />} />
					{/* External Layout */}
					<Route element={<ExternalLayout />}>
						<Route path="/forgot-password" element={<UserForgotPassword />} />
						<Route path="/register" element={<UserRegister />} />
						<Route path="/update-password" element={<UserUpdatePassword />} />
						<Route
							path="/update-password/:token"
							element={<UserUpdatePassword />}
						/>
					</Route>
					{/* Main Layout */}
					<Route element={<MainLayout />}>
						<Route path="/courses" element={<CourseList />} />
						<Route path="/certificates" element={<CourseCertificates />} />
						<Route path="/profile" element={<ProfileIndex />} />
						<Route path="/units/index/:memberId" element={<UnitsView />} />
						<Route
							path="/resources/view/:memberId/:unitId/:lessonId/:resourceId"
							element={<ResourceView />}
						/>
						<Route path="/faqs" element={<PagesFaqs />} />
					</Route>
					{/* 404 */}
					<Route path="*" element={<FourZeroFour />} />
				</Routes>
			</BrowserRouter>
		</React.Suspense>
	);
}
