import { SVGProps } from 'react';

export default function LogoGray(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={102}
			height={22}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g clipPath="url(#a)">
				<path
					d="M33.138 2.583s-.238-.395-.361-.581l-.007-.015A4.377 4.377 0 0 0 31.184.53a4.408 4.408 0 0 0-4.189 0 4.377 4.377 0 0 0-1.586 1.458l-.034.056c-.02.031-.04.061-.058.093l-4.463 7.198-.141-.225v12.888h.155c.083 0 .163 0 .244-.008.033 0 .066 0 .1-.008l.155-.014.113-.014c.047-.007.095-.015.142-.024l.111-.02c.074-.014.147-.031.218-.05l.133-.038.1-.029c.046-.016.093-.03.132-.047.04-.017.06-.021.089-.032l.141-.055.052-.024c.105-.044.209-.094.31-.148l.035-.018c.049-.027.098-.055.146-.084a2.654 2.654 0 0 0 .198-.123l.04-.027c.157-.11.309-.228.452-.355l.024-.02c.045-.043.09-.085.132-.132l.027-.026a4.34 4.34 0 0 0 .525-.636c.02-.032.043-.062.064-.095l.036-.058.02-.031 5.032-8.122s3.378-5.452 7.762-2.29l-4.263-6.886Z"
					fill="#A098AE"
				/>
				<path
					d="M37.261 22.002c2.457 0 4.448-1.98 4.448-4.421 0-2.442-1.991-4.42-4.448-4.42-2.456 0-4.448 1.978-4.448 4.42 0 2.441 1.992 4.42 4.448 4.42ZM27.074 15.914c-1.573.926-2.684-.869-2.684-.869l-.82-1.313-7.182-11.598c-.018-.032-.038-.062-.058-.093l-.034-.056A4.374 4.374 0 0 0 13.503.066a4.414 4.414 0 0 0-3.334.636 4.375 4.375 0 0 0-1.88 2.81 4.336 4.336 0 0 0 .699 3.301l8.1 13.073.02.032.035.058a4.372 4.372 0 0 0 1.617 1.499 4.404 4.404 0 0 0 5.851-1.604v.017l2.463-3.974Z"
					fill="#DBDBDB"
				/>
				<path
					d="m16.832 2.767-.012.061-.511-.826A4.375 4.375 0 0 0 14.726.535 4.406 4.406 0 0 0 8.94 1.988l-.034.055-.058.093L.749 15.2a4.337 4.337 0 0 0-.609 3.554 4.363 4.363 0 0 0 1.988 2.633 4.41 4.41 0 0 0 3.273.502 4.386 4.386 0 0 0 2.693-1.916l.037-.058c0-.01.012-.021.019-.032l7.224-11.66.553-.848c1.12-1.437 2.908-.194 2.908-.194l1.253.92-3.256-5.334Z"
					fill="#A098AE"
				/>
				<path
					d="M48.892 15.267c0-2.82 2.142-4.658 4.697-4.658 1.245 0 2.734.435 3.701 1.971l-1.283.88a2.801 2.801 0 0 0-2.362-1.182c-1.617 0-2.952 1.16-2.952 2.998 0 1.838 1.348 2.998 2.965 2.998.912 0 1.76-.346 2.349-1.183l1.283.88c-.962 1.531-2.45 1.971-3.7 1.971-2.569-.02-4.698-1.856-4.698-4.675ZM62.175 10.738h1.76v7.452h3.553v1.607h-5.313v-9.059ZM77.23 10.738l3.517 9.065h-1.9l-.604-1.633h-3.875l-.604 1.633h-1.9l3.504-9.065h1.861Zm.422 5.819-1.347-3.64-1.348 3.637 2.695.003ZM84.79 17.665l1.424-.65c.256.701.834 1.326 1.758 1.326.719 0 1.335-.37 1.335-1.161 0-.657-.397-.956-1.322-1.327l-.578-.217c-1.245-.496-2.272-1.182-2.272-2.563 0-1.518 1.27-2.463 2.812-2.463a3.108 3.108 0 0 1 1.762.505 3.08 3.08 0 0 1 1.164 1.408l-1.31.726c-.384-.726-.885-1.084-1.553-1.084-.667 0-1.142.32-1.142.92 0 .473.265.842 1.27 1.224l.616.244c1.587.638 2.389 1.351 2.389 2.64 0 1.84-1.618 2.73-3.146 2.73-1.591 0-2.887-.931-3.208-2.258ZM95.647 17.665l1.424-.65c.257.701.834 1.326 1.758 1.326.72 0 1.335-.37 1.335-1.161 0-.657-.396-.956-1.321-1.327l-.578-.217c-1.245-.496-2.272-1.182-2.272-2.563 0-1.518 1.271-2.463 2.811-2.463a3.106 3.106 0 0 1 1.762.505c.523.341.929.832 1.165 1.408l-1.31.726c-.385-.726-.886-1.084-1.553-1.084-.668 0-1.142.32-1.142.92 0 .473.264.842 1.271 1.224l.616.244c1.586.638 2.387 1.351 2.387 2.64 0 1.84-1.618 2.73-3.144 2.73-1.594 0-2.888-.931-3.21-2.258ZM49.016 2.145h1.47l2.261 5.18 2.258-5.18h1.47v6.878h-1.334V4.705h-.045L53.23 9.023h-.984l-1.85-4.318h-.049v4.318h-1.332V2.145ZM65.926 2.145h1.33v6.878h-1.334l.004-6.878ZM79.603 2.145h1.336L84.11 6.84h.049V2.145h1.337v6.878h-1.335L80.99 4.327h-.047v4.696h-1.335l-.005-6.878ZM97.85 2.145h3.916v1.22h-2.581v1.601h2.174v1.201h-2.174v1.636h2.668v1.22H97.85V2.145Z"
					fill="#DBDBDB"
				/>
			</g>
			<defs>
				<clipPath id="a">
					<path fill="#fff" d="M0 0h102v22H0z" />
				</clipPath>
			</defs>
		</svg>
	);
}
