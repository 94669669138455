import cn from 'classnames';

type ImageProps = {
	src?: string;
	alt: string;
	styles?: object;
	className?: string;
	lazy?: boolean;
};

export const MyImage = (props: ImageProps) => {
	const _lazy = props.lazy ?? true;

	if (!props.src) {
		return null;
	}

	return (
		<img
			loading={_lazy ? 'lazy' : undefined}
			decoding={_lazy ? 'async' : undefined}
			role="presentation"
			src={props.src}
			alt={props.alt}
			className={cn('image', props.className)}
			style={props.styles}
		/>
	);
};
