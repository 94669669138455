import { NavLink } from 'react-router-dom';
import { BreadcrumbComponentType } from 'use-react-router-breadcrumbs';
import { useResourceStore } from '../../stores/courses';

export const ResourcesViewMemberIdUnitIdLessonIdResourceIdBreadcrumb: BreadcrumbComponentType<
	'memberId' | 'unitId' | 'lessonId' | 'resourceId'
> = ({ location }) => {
	const resource = useResourceStore((state) => state.resource);

	if (!resource) {
		// Si no está el curso, no se muestra el breadcrumb.
		return null;
	}

	return <NavLink to={location.pathname}>{resource.Resource.name}</NavLink>;
};
