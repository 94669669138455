import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { appApiUrl } from '../../../Utils/env';
import getXHost from '../../../Utils/getXHost';
import { type ManifestType } from '../AppConfigContext';

export const useManifest = () => {
	return useQuery({
		queryKey: ['manifest'],
		queryFn: async () => {
			const res = await axios.get<ManifestType>(`${appApiUrl}/manifest.json`, {
				headers: {
					'X-Host': getXHost(),
				},
			});
			return res;
		},
		cacheTime: 10 * 60 * 1000,
	});
};
