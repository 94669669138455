import { NavLink } from 'react-router-dom';
import { BreadcrumbComponentType } from 'use-react-router-breadcrumbs';
import { useCourseStore } from '../../stores/courses';

export const UnitsIndexMemberIdBreadcrumb: BreadcrumbComponentType<
	'memberId'
> = ({ location }) => {
	const course = useCourseStore((state) => state.course);

	if (!course) {
		return null;
	}

	return <NavLink to={location.pathname}>{course.name}</NavLink>;
};
