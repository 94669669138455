import { WarningIcon } from '../svg/WarningIcon';
import './styles.scss';

export const FiveHundred = () => {
	return (
		<div className="error-view">
			<h1 className="title">Error 500, algo salió mal</h1>
			<div>
				Siempre puedes <a href="/">volver a inicio</a>
			</div>
			<div className="message">
				Contacta a soporte para recibir ayuda en caso de que sigas viendo este
				error.
			</div>
			<div className="image">
				<WarningIcon />
			</div>
		</div>
	);
};
