export const UnitsView = {
	Certificate: 'Certificado',
	'Approved details.':
		'Para finalizar tu curso y tener tu nota haz clic en el botón "Finaliza tu curso".',
	'Unit list': 'Listado de módulos',
	'Finish your course': 'Finaliza tu curso',
	'Course of': 'Curso de "{{course}}"',
	'Complete your course': 'Completa tu curso',
	'Closing course...': 'Cerrando tu curso...',
	'Download certificate': 'Descargar certificado',
	'Downloading...': 'Descargando...',
	'Not reach the grade needed':
		'No alcanzaste la nota que se necesita para aprobar el curso, puedes comunicarte con nuestro equipo de soporte para revisar tu situación.',
};
