import { SVGProps } from 'react';

export default function LogoColor(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={236}
			height={51}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
			viewBox="0 0 236 51"
		>
			<g clipPath="url(#a)">
				<path
					d="M76.67 5.985s-.55-.914-.834-1.346l-.016-.034a10.134 10.134 0 0 0-3.67-3.38 10.184 10.184 0 0 0-9.691 0 10.134 10.134 0 0 0-3.67 3.38l-.08.128c-.046.073-.091.143-.134.216L48.25 21.636l-.327-.521v29.876h.36c.19 0 .377 0 .563-.018.077 0 .153 0 .23-.018l.36-.034.26-.033c.11-.014.22-.033.33-.055l.258-.046c.171-.033.34-.073.504-.115l.306-.089.233-.067c.107-.036.214-.07.306-.11.091-.039.137-.048.204-.072l.328-.128.119-.055a8.59 8.59 0 0 0 .716-.344l.082-.043a9.567 9.567 0 0 0 .465-.271c.113-.067.226-.137.333-.21l.089-.061c.366-.253.716-.527 1.049-.822l.055-.05c.104-.096.208-.194.306-.304l.061-.06a10.022 10.022 0 0 0 1.214-1.475c.05-.073.101-.143.15-.219l.083-.134.045-.073 11.643-18.827s7.817-12.64 17.958-5.308L76.671 5.985Z"
					fill="url(#b)"
				/>
				<path
					d="M86.212 51.002c5.684 0 10.291-4.588 10.291-10.248 0-5.66-4.607-10.248-10.291-10.248s-10.291 4.588-10.291 10.248c0 5.66 4.607 10.248 10.291 10.248Z"
					fill="#EB5A49"
				/>
				<path
					d="M62.643 36.894c-3.64 2.147-6.209-2.014-6.209-2.014l-1.899-3.045L37.92 4.949c-.043-.073-.089-.143-.135-.216l-.08-.128a10.13 10.13 0 0 0-2.78-2.871A10.176 10.176 0 0 0 27.238.12a10.178 10.178 0 0 0-3.708 1.51 10.134 10.134 0 0 0-2.833 2.82 10.088 10.088 0 0 0-1.482 7.682c.277 1.314.815 2.56 1.582 3.665l18.74 30.306.047.073.082.134a10.125 10.125 0 0 0 3.74 3.475 10.175 10.175 0 0 0 9.9-.14 10.121 10.121 0 0 0 3.64-3.578v.04l5.697-9.213Z"
					fill="#9C3732"
				/>
				<path
					d="m38.947 6.411-.028.144-1.183-1.916a10.127 10.127 0 0 0-3.664-3.4 10.178 10.178 0 0 0-9.706-.02 10.127 10.127 0 0 0-3.677 3.386l-.08.128-.134.217L1.734 35.234c-.147.22-.285.442-.413.673a10.071 10.071 0 0 0-.996 7.566 10.113 10.113 0 0 0 4.599 6.104 10.187 10.187 0 0 0 7.573 1.163 10.149 10.149 0 0 0 6.232-4.442l.085-.134c0-.024.028-.048.043-.073l16.717-27.029 1.278-1.967c2.59-3.332 6.728-.45 6.728-.45l2.9 2.131-7.533-12.365Z"
					fill="url(#c)"
				/>
				<path
					d="M113.122 35.394c0-6.536 4.958-10.796 10.869-10.796 2.881 0 6.325 1.008 8.563 4.568l-2.969 2.04a6.445 6.445 0 0 0-2.386-2.057 6.472 6.472 0 0 0-3.079-.684c-3.741 0-6.83 2.69-6.83 6.95s3.12 6.95 6.86 6.95c2.11 0 4.071-.8 5.435-2.74l2.969 2.04c-2.226 3.548-5.67 4.568-8.563 4.568-5.942-.046-10.869-4.303-10.869-10.839ZM143.854 24.895h4.071v17.274h8.224v3.724h-12.295V24.895ZM178.688 24.895l8.138 21.013h-4.395l-1.397-3.785h-8.967l-1.398 3.785h-4.395l8.108-21.014h4.306Zm.979 13.488-3.117-8.436-3.119 8.43 6.236.006ZM196.179 40.953l3.297-1.507c.593 1.626 1.929 3.076 4.067 3.076 1.664 0 3.089-.859 3.089-2.692 0-1.523-.918-2.218-3.058-3.076l-1.337-.503c-2.881-1.151-5.257-2.74-5.257-5.942 0-3.52 2.939-5.71 6.505-5.71a7.182 7.182 0 0 1 4.076 1.171 7.137 7.137 0 0 1 2.695 3.263l-3.031 1.684c-.89-1.684-2.049-2.512-3.593-2.512-1.545 0-2.643.74-2.643 2.132 0 1.096.612 1.952 2.939 2.838l1.425.564c3.67 1.48 5.527 3.133 5.527 6.121 0 4.264-3.744 6.329-7.279 6.329-3.682 0-6.679-2.16-7.422-5.236ZM221.299 40.953l3.297-1.507c.593 1.626 1.929 3.076 4.067 3.076 1.664 0 3.089-.859 3.089-2.692 0-1.523-.918-2.218-3.058-3.076l-1.337-.503c-2.881-1.151-5.257-2.74-5.257-5.942 0-3.52 2.942-5.71 6.505-5.71a7.181 7.181 0 0 1 4.077 1.17 7.135 7.135 0 0 1 2.694 3.264l-3.031 1.684c-.89-1.684-2.049-2.512-3.593-2.512-1.545 0-2.643.74-2.643 2.132 0 1.096.612 1.952 2.943 2.838l1.425.564c3.67 1.48 5.523 3.134 5.523 6.121 0 4.264-3.743 6.329-7.276 6.329-3.688 0-6.682-2.16-7.425-5.236Z"
					fill="#9C3732"
				/>
				<path
					d="M113.409 4.973h3.404l5.23 12.008 5.223-12.008h3.404v15.946h-3.089v-10.01h-.104l-4.315 10.01h-2.275l-4.282-10.01h-.113v10.01h-3.083V4.973ZM152.534 4.973h3.079v15.946h-3.089l.01-15.946ZM184.181 4.973h3.089l7.339 10.887h.114V4.973h3.094v15.946h-3.088l-7.34-10.888h-.11V20.92h-3.089l-.009-15.946ZM226.397 4.973h9.062v2.829h-5.973v3.712h5.031v2.784h-5.031v3.792h6.175v2.829h-9.264V4.973Z"
					fill="#F05948"
				/>
			</g>
			<defs>
				<linearGradient
					id="b"
					x1={67.242}
					y1={0.001}
					x2={67.242}
					y2={51.001}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#F05948" />
					<stop offset={0.21} stopColor="#EC5747" />
					<stop offset={0.43} stopColor="#DF5243" />
					<stop offset={0.66} stopColor="#C9493E" />
					<stop offset={0.9} stopColor="#AB3D36" />
					<stop offset={1} stopColor="#9C3732" />
				</linearGradient>
				<linearGradient
					id="c"
					x1={23.24}
					y1={0.001}
					x2={23.24}
					y2={51.001}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#F05948" />
					<stop offset={0.21} stopColor="#EC5747" />
					<stop offset={0.43} stopColor="#DF5243" />
					<stop offset={0.66} stopColor="#C9493E" />
					<stop offset={0.9} stopColor="#AB3D36" />
					<stop offset={1} stopColor="#9C3732" />
				</linearGradient>
				<clipPath id="a">
					<path fill="#fff" d="M0 0h236v51H0z" />
				</clipPath>
			</defs>
		</svg>
	);
}
