const initFreshChat = (codeChat) => {
	window.initFreshChat = function () {
		window.fcWidget?.init({
			token: codeChat.freshchat.value,
			host: 'https://wchat.freshchat.com',
			tags: codeChat.freshchat.params.tags,
			// Setting FAQ Tags in the object below.
			faqTags: {
				// Array of Tags
				tags: codeChat.freshchat.params.tags,
				filterType: 'category',
			},
		});
	};
	window.fsInitialize = function (i, t) {
		let e;
		// eslint-disable-next-line no-unused-expressions
		i.getElementById(t)
			? window.initFreshChat()
			: (((e = i.createElement('script')).id = t),
			  (e.async = !0),
			  (e.src = 'https://wchat.freshchat.com/js/widget.js'),
			  (e.onload = window.initFreshChat),
			  i.head.appendChild(e));
	};

	window.fsInitialize(document, 'freshchat-js-sdk');
};

export default initFreshChat;
