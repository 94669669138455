import React from 'react';
import {
	BookFill,
	CalendarFill,
	CardChecklist,
	List,
	PersonCircle,
	XCircle,
} from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import {
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	Button,
	Offcanvas,
	OffcanvasBody,
	OffcanvasHeader,
	Nav,
	NavItem,
} from 'reactstrap';

import HeaderItem from './HeaderItem';

import './styles.scss';
import LogoApp from '../logo-app/LogoApp';
import { useUserStore } from '../../stores/user';
import useProfile from '../../Hooks/useProfile';

export default function HeaderNavBar() {
	useProfile();
	const [userLogout, userInfo] = useUserStore((state) => [
		state.userLogout,
		state.user,
	]);
	const { t } = useTranslation('Common');
	const [isOpen, setIsOpen] = React.useState(false);

	const logout = () => {
		userLogout();
	};

	const go = () => {
		setIsOpen(false);
	};

	return (
		<div className="HeaderNavBar p-3 fixed-top bg-white shadow-sm">
			<div className="HeaderNavBar_Wrapper d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
				<Link
					to={'/courses'}
					className="HeaderNavBar_Wrapper_ImgLink d-flex align-items-center mb-2 mb-lg-0 text-dark text-decoration-none"
				>
					<LogoApp />
				</Link>
				<Button
					className="d-lg-none menu-mobile navbar-toggler ms-auto"
					onClick={() => setIsOpen((_v) => !_v)}
					color={'link'}
				>
					<List size={25} />
				</Button>
				<Offcanvas
					isOpen={isOpen}
					toggle={() => setIsOpen((_v) => !_v)}
					direction="end"
					className="bg-secondary text-white"
				>
					<OffcanvasHeader
						toggle={() => setIsOpen((_v) => !_v)}
						className="text-white"
					/>
					<OffcanvasBody>
						<Nav className="justify-content-end flex-grow-1 pe-3" navbar>
							<NavItem>
								<NavLink
									className="text-white nav-link"
									to="/courses/"
									onClick={go}
								>
									<BookFill /> {t('Courses')}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className="text-white nav-link"
									to="/certificates"
									onClick={go}
								>
									<CalendarFill /> {t('Certificates')}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className="text-white nav-link"
									to="/faqs"
									onClick={go}
								>
									<CardChecklist /> {t('Frequent questions')}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className="text-white nav-link"
									to="/profile"
									onClick={go}
								>
									<PersonCircle /> {t('My profile')}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className="text-white nav-link"
									to="/"
									onClick={logout}
								>
									<XCircle /> {t('Logout')}
								</NavLink>
							</NavItem>
						</Nav>
					</OffcanvasBody>
				</Offcanvas>
				<div className="HeaderNavBar_Wrapper_NavLinks text-end ms-auto d-none d-lg-block">
					<UncontrolledDropdown setActiveFromChild>
						<DropdownToggle caret className="nav-link" tag="a">
							{userInfo?.name}
						</DropdownToggle>
						<DropdownMenu>
							<HeaderItem to={'profile'} icon={<PersonCircle />}>
								{t('My profile')}
							</HeaderItem>
							<HeaderItem to={''} icon={<XCircle />} onClick={logout}>
								{t('Logout')}
							</HeaderItem>
						</DropdownMenu>
					</UncontrolledDropdown>
				</div>
			</div>
		</div>
	);
}
