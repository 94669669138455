import { create } from 'zustand';
import { produce } from 'immer';

export type Alert = {
	title?: string;
	message: string;
	action?: { label: string; click: () => void };
};

type AlertsState = {
	alert?: Alert;
	addAlert: (args: Alert) => void;
	cleanAlert: () => void;
};

export const useAlerts = create<AlertsState>((set) => ({
	alert: undefined,
	addAlert: (args) =>
		set(
			produce((state) => {
				state.alert = args;
			})
		),
	cleanAlert: () =>
		set(
			produce((state) => {
				state.alert = undefined;
			})
		),
}));
