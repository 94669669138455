import React from 'react';
import './styles.scss';

type MyModalBodyProps = {
	children: React.ReactNode;
};

const MyModalBody = (props: MyModalBodyProps) => {
	return <div className="my-modal-body">{props.children}</div>;
};

type MyModalProps = {
	children: React.ReactNode;
	isOpen: boolean;
};

export const MyModal = (props: MyModalProps) => {
	if (!props.isOpen) {
		return null;
	}

	return (
		<div className="my-modal">
			{props.children}
			<div className="my-modal-backdrop" />
		</div>
	);
};

MyModal.Body = MyModalBody;
