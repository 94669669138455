import { NavLink } from 'react-router-dom';
import { BreadcrumbComponentType } from 'use-react-router-breadcrumbs';
import { useCourseStore } from '../../stores/courses';

export const ResoucesViewMemberIdUnitIdBreadcrumb: BreadcrumbComponentType<
	'memberId' | 'unitId' | 'lessonId' | 'resourceId'
> = ({ match }) => {
	const course = useCourseStore((state) => state.course);
	const unit = course?.Units.find((u) => u.Unit.id === match.params.unitId);

	if (!unit) {
		// Si no está el curso, no se muestra el breadcrumb.
		return null;
	}

	return (
		<NavLink
			to={`/units/index/${match.params.memberId}?unitId=${match.params.unitId}`}
		>
			{unit.Unit.name}
		</NavLink>
	);
};
