export const UserLogin = {
	'Sign in': 'Sign in',
	'Please type your username and password':
		'Please type your username and password',
	Email: 'Email',
	'DNI or Email': 'RUT (sin puntos y con guión) ó Correo electrónico',
	Password: 'Password',
	'Forgot your password?': 'Forgot your password?',
	Enter: 'Enter',
	'Stil not registered?': 'Stil not registered?',
	'Sign up here!': 'Sign up here!',
};
