import { Link, useLocation } from 'react-router-dom';
import { DropdownItem } from 'reactstrap';

type Props = {
	children: React.ReactNode;
	to: string;
	icon?: React.ReactNode;
	onClick?: () => void;
};

export default function HeaderItem({ children, to, icon, onClick }: Props) {
	const location = useLocation();
	const _to = to.startsWith('/') ? to : `/${to}`;

	const isActive = location.pathname === _to;

	return (
		<DropdownItem
			active={isActive}
			tag={Link}
			to={_to}
			onClick={(e) => {
				if (onClick) {
					e.preventDefault();
					onClick();
				}
			}}
		>
			{icon} {children}
		</DropdownItem>
	);
}
