import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type User = {
	id?: string;
	firstname?: string;
	lastname?: string;
	email?: string;
	image?: string | null;
	birthday?: string | null;
	name?: string;
	dni?: string;
};

type useUserStoreType = {
	user?: User;
	token?: string;
	isLogged: boolean;
	setUserData: (arg: User) => void;
	setUserToken: (arg: string) => void;
	userLogout: () => void;
};

export const useUserStore = create(
	persist<useUserStoreType>(
		(set) => ({
			user: undefined,
			token: undefined,
			isLogged: false,
			setUserData: (userData) => set(() => ({ user: userData })),
			setUserToken: (token) => set(() => ({ token, isLogged: true })),
			userLogout: () =>
				set(() => ({ user: undefined, token: undefined, isLogged: false })),
		}),
		{ name: 'user-store' }
	)
);
