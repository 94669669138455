import {
	BookFill,
	CalendarFill,
	CardChecklist,
	CaretLeftFill,
	CaretRightFill,
} from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

import { Button } from 'reactstrap';
import { LogoColor, LogoIcon } from '../../Assets';

import SidebarItem from './SidebarItem';

import './styles.scss';

type SidebarMenuProps = {
	context: {
		isOpen: boolean;
		setIsOpen: (isOpen: boolean) => void;
	};
};

export default function SidebarMenu({
	context: { isOpen, setIsOpen },
}: SidebarMenuProps) {
	const { t } = useTranslation('Common');

	const toggle = () => setIsOpen(!isOpen);

	return (
		<div
			className={`d-none d-lg-block SidebarMenu ${isOpen ? 'open' : 'close'}`}
		>
			<Button
				color="primary"
				onClick={toggle}
				className={`SidebarMenu_Toggle ${isOpen ? 'open' : 'close'}`}
			>
				{isOpen ? <CaretLeftFill /> : <CaretRightFill />}
			</Button>
			<div className="SidebarMenu_Options">
				<SidebarItem sidebarIsOpen={isOpen} to="courses" icon={<BookFill />}>
					{t('Courses')}
				</SidebarItem>
				<SidebarItem
					sidebarIsOpen={isOpen}
					to="certificates"
					icon={<CalendarFill />}
				>
					{t('Certificates')}
				</SidebarItem>
				<SidebarItem sidebarIsOpen={isOpen} to="faqs" icon={<CardChecklist />}>
					{t('Frequent questions')}
				</SidebarItem>
			</div>
			<div className="SidebarMenu_Logo">
				<LogoColor className="HideOnOpen" />
				<LogoIcon className="HideOnClose" />
			</div>
		</div>
	);
}
