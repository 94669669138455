import { SVGProps } from 'react';

export default function LogoIcon(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			id="Capa_1"
			xmlns="http://www.w3.org/2000/svg"
			x={0}
			y={0}
			viewBox="0 0 90.3 48"
			style={{
				// @ts-expect-error Type '{ enableBackground: string; }' is not assignable to type 'Properties<string | ...
				enableBackground: 'new 0 0 90.3 48',
			}}
			xmlSpace="preserve"
			{...props}
		>
			<style>
				{
					'.st3{fill:url(#SVGID_00000172416171777037262050000009052154279582303400_)}'
				}
			</style>
			<linearGradient
				id="SVGID_1_"
				gradientUnits="userSpaceOnUse"
				x1={62.95}
				y1={742}
				x2={62.95}
				y2={790}
				gradientTransform="translate(0 -742)"
			>
				<stop
					offset={0}
					style={{
						stopColor: '#f05948',
					}}
				/>
				<stop
					offset={0.21}
					style={{
						stopColor: '#ec5747',
					}}
				/>
				<stop
					offset={0.43}
					style={{
						stopColor: '#df5243',
					}}
				/>
				<stop
					offset={0.66}
					style={{
						stopColor: '#c9493e',
					}}
				/>
				<stop
					offset={0.9}
					style={{
						stopColor: '#ab3d36',
					}}
				/>
				<stop
					offset={1}
					style={{
						stopColor: '#9c3732',
					}}
				/>
			</linearGradient>
			<path
				d="M71.8 5.6s-.5-.9-.8-1.3c-.9-1.3-2-2.4-3.4-3.2C66.2.4 64.6 0 63 0s-3.1.4-4.5 1.2-2.6 1.9-3.4 3.2l-.1.1c0 .1-.1.1-.1.2l-9.7 15.7-.3-.5V48h1.6c.1 0 .2 0 .3-.1h.2c.2 0 .3-.1.5-.1l.3-.1.2-.1c.1 0 .2-.1.3-.1s.1 0 .2-.1l.3-.1.1-.1c.2-.1.5-.2.7-.3h.1c.1-.1.2-.1.3-.2 0 0 .1 0 .1-.1.1-.1.2-.1.3-.2l.1-.1c.3-.2.7-.5 1-.8h.1l.3-.3.1-.1c.4-.4.8-.9 1.1-1.4 0-.1.1-.1.1-.2l.1-.1v-.1l10.9-17.7s7.3-11.9 16.8-5l-9.2-15z"
				style={{
					fill: 'url(#SVGID_1_)',
				}}
			/>
			<path
				d="M80.7 48c5.3 0 9.6-4.3 9.6-9.6s-4.3-9.6-9.6-9.6-9.6 4.3-9.6 9.6 4.3 9.6 9.6 9.6z"
				style={{
					fill: '#eb5a49',
				}}
			/>
			<path
				d="M58.7 34.7c-3.4 2-5.8-1.9-5.8-1.9L51.1 30 35.5 4.7c0-.1-.1-.1-.1-.2l-.1-.1c-.7-1.1-1.6-2-2.6-2.7S30.5.5 29.3.2c-1.2-.3-2.5-.3-3.8 0-1.2.2-2.4.7-3.5 1.4s-2 1.6-2.7 2.7-1.1 2.1-1.3 3.4c-.2 1.2-.2 2.5 0 3.8.3 1.2.8 2.4 1.5 3.4L37 43.4v.1l.1.1c.9 1.4 2.1 2.5 3.5 3.3s3 1.2 4.7 1.1c1.6 0 3.2-.5 4.6-1.3 1.4-.8 2.6-2 3.4-3.4l5.4-8.6z"
				style={{
					fill: '#9c3732',
				}}
			/>
			<linearGradient
				id="SVGID_00000019665536121159931670000014229525029897291656_"
				gradientUnits="userSpaceOnUse"
				x1={21.75}
				y1={742}
				x2={21.75}
				y2={789.973}
				gradientTransform="translate(0 -742)"
			>
				<stop
					offset={0}
					style={{
						stopColor: '#f05948',
					}}
				/>
				<stop
					offset={0.21}
					style={{
						stopColor: '#ec5747',
					}}
				/>
				<stop
					offset={0.43}
					style={{
						stopColor: '#df5243',
					}}
				/>
				<stop
					offset={0.66}
					style={{
						stopColor: '#c9493e',
					}}
				/>
				<stop
					offset={0.9}
					style={{
						stopColor: '#ab3d36',
					}}
				/>
				<stop
					offset={1}
					style={{
						stopColor: '#9c3732',
					}}
				/>
			</linearGradient>
			<path
				style={{
					fill: 'url(#SVGID_00000019665536121159931670000014229525029897291656_)',
				}}
				d="m36.5 6-1.1-1.7c-.9-1.3-2-2.4-3.4-3.2C30.5.4 29 0 27.4 0s-3.2.4-4.5 1.1c-1.4.8-2.6 1.9-3.4 3.2l-.1.1-.1.2L1.6 33.2c-.1.2-.3.4-.4.6C0 36-.3 38.5.3 40.9s2.2 4.5 4.3 5.7c2.1 1.3 4.7 1.7 7.1 1.1 2.4-.6 4.5-2.1 5.8-4.2l.1-.1v-.1l15.7-25.4 1.2-1.9c2.4-3.1 6.3-.4 6.3-.4l2.7 2-7-11.6z"
			/>
		</svg>
	);
}
