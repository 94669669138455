import React, { Dispatch, SetStateAction } from 'react';

export type ImageBrandingType = {
	id: number;
	value: string;
};

export type BrandingType = {
	logo?: ImageBrandingType;
	background_signin?: ImageBrandingType;
	background_signup?: ImageBrandingType;
	background_recover_pswd?: ImageBrandingType;
	background_change_recover_pswd?: ImageBrandingType;
};

type chatCodeType = {
	freshchat: {
		id: number;
		value: string;
		params: {
			tags: string[];
		};
	};
};

export type ManifestType = {
	lang: string;
	name: string;
	description: string;
	theme_color: string;
	background_color: string;
	categories: string[];
	branding?: BrandingType;
	code_chat?: chatCodeType;
};

type ContextState = {
	manifest: ManifestType;
	setManifest: Dispatch<SetStateAction<ManifestType>>;
};

const AppConfigContext = React.createContext<ContextState>({
	manifest: {} as ManifestType,
	setManifest: () => {},
});

export const useAppConfigContext = () => {
	const appConfig = React.useContext(AppConfigContext);
	if (appConfig !== null) {
		return appConfig;
	}

	throw Error(
		'You should use useAppConfigContext only in a descendant of AppConfigProvider'
	);
};

export const AppConfigProvider = AppConfigContext.Provider;
export default AppConfigContext;
