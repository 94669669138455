import { UserLogin } from './UserLogin';
import { UnitsView } from './UnitsView';
import { Quiz } from './Quiz';
import { translation } from './translation';

export const en = {
	translation,
	UserLogin,
	UnitsView,
	Quiz,
};
