import { isDevelopment, appXHost } from './env';

const getXHost = () => {
	if (isDevelopment) {
		return appXHost;
	}
	return document.location.hostname;
};

export default getXHost;
