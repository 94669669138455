import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppConfigProvider, ManifestType } from './AppConfigContext';
import i18next from 'i18next';
import { useManifest } from './hooks/useManifest';
import Loading from '../loading/Loading';

type Props = {
	children: React.ReactNode;
};

export default function AppConfig({ children }: Props) {
	const { t } = useTranslation('Common');

	const [manifest, setManifest] = useState<ManifestType>({
		name: t('LMS'),
		description: t('A big LMS'),
		background_color: '#ffffff',
		theme_color: '#dd3d31',
		categories: [],
		lang: 'en-US',
	});
	const { data, isLoading } = useManifest();

	useEffect(() => {
		if (data?.data.name) {
			setManifest(data?.data);
			if (data?.data.lang) {
				const [base] = data.data.lang.split('-');
				i18next.changeLanguage(base);
			}
		}
	}, [data]);

	if (isLoading) {
		return <Loading />;
	}

	return (
		<AppConfigProvider value={{ manifest, setManifest }}>
			<div className="AppConfig">{children}</div>
		</AppConfigProvider>
	);
}
