/** @see User */
/** Login del Usuario. */
export const USER_LOGIN = 'USER_LOGIN';
/** Cierre de sesión del usuario. */
export const USER_LOGOUT = 'USER_LOGOUT';
/** Perfil del usuario. */
export const USER_PROFILE = 'USER_PROFILE';
/** Enrrolamiento de un nuevo usuario. */
export const USER_ENROLLMENT = 'USER_ENROLLMENT';

/** @see Common */
/** Limpiar el último código de error */
export const COMMON_CLEAN_ERROR = 'COMMON_CLEAN_ERROR';
